<template>
  <b-form-group
    label="Geçerlilik Tarihi"
    label-for="vdate"
  >
    <validation-provider
      #default="{ errors }"
      name="Teklif Tarihi"
      rules="required"
    >
      <b-form-datepicker
        id="vdate"
        v-model="dataItem.vdate"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ValidityDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  mounted() {
    this.setValidityDate()
  },
  methods: {
    setValidityDate() {
      if (!this.dataItem.vdate) {
        this.dataItem.vdate = this.moment().add(30, 'days').format('YYYY-MM-DD')
      }
    },
  },
}
</script>
