<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Ürün Grubu"
        :label-for="'id_product_groups_' + itemKey"
      >
        <validation-provider
          #default="{ errors }"
          name="Ürün Grubu"
          rules="required"
        >
          <v-select
            :id="'id_product_groups_' + itemKey"
            v-model="dataItem.offer_lines[itemKey].id_product_groups"
            :options="productGroups"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            @input="changeGroup"
          >
            <template v-slot:option="option">
              <div class="text-info font-weight-bold">
                {{ option.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ option.main_group }}
              </div>
              <div class="font-small-2 text-primary">
                {{ option.brand }}
              </div>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <b-form-group
        label="Ürün Kartı"
        :label-for="'id_products_' + itemKey"
      >
        <validation-provider
          #default="{ errors }"
          name="Ürün Kartı"
          rules="required"
        >
          <v-select
            :id="'id_products_' + itemKey"
            v-model="dataItem.offer_lines[itemKey].id_products"
            :options="products"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="!products"
            @input="setTitle"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Product',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    idProductGroups: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      products: [],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    this.getProductGroups()
    localize('tr')
  },
  mounted() {
    this.processData()
  },
  methods: {
    processData() {
      if (this.idProductGroups) {
        this.getProducts()
      }
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
          'brands.title AS brand',
          'main_groups.title AS main_group',
        ],
        where: {
          'main_groups.id !=': null,
          'product_groups.id_brands': this.dataItem.id_brands,
        },
      })
    },
    changeGroup() {
      this.dataItem.offer_lines[this.itemKey].id_products = null
      this.getProducts()
    },
    getProducts() {
      if (this.dataItem.offer_lines[this.itemKey].id_product_groups) {
        this.$store.dispatch('products/getDataList', {
          select: [
            'products.id AS id',
            'products.title AS title',
          ],
          where: {
            'products.id_product_groups': this.dataItem.offer_lines[this.itemKey].id_product_groups,
          },
        })
          .then(response => {
            if (response) {
              this.products = response
            }
          })
      }
    },
    setTitle() {
      this.dataItem.offer_lines[this.itemKey].title = this.products.find(e => e.id === this.dataItem.offer_lines[this.itemKey].id_products).title
    },
  },
}
</script>
