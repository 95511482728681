<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    small
  >
    <template #cell(interview_subject)="data">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <b-button
            pill
            class="btn-icon"
            variant="flat-success"
            :to="'/app/interviews/view/' + data.item.id"
            target="_blank"
          >
            <FeatherIcon icon="EyeIcon" />
          </b-button>
        </div>
        <div>
          <div>
            {{ data.item.interview_subject }}
          </div>
          <div
            v-if="data.item.modified"
            class="text-primary font-small-2"
          >
            {{ moment(data.item.modified).format('llll') }}
          </div>
          <div
            v-else
            class="text-primary font-small-2"
          >
            {{ moment(data.item.created).format('llll') }}
          </div>
        </div>
      </div>
    </template>
    <template #cell(interview_status)="data">
      <div>
        {{ data.item.interview_status }}
      </div>
      <div class="text-primary font-small-2">
        {{ data.item.status === '0'? 'Açık Görüşme' : 'Kapalı Görüşme' }}
      </div>
    </template>
    <template #cell(interview_status)="data">
      <div>
        {{ data.item.interview_status }}
      </div>
      <div
        class="font-small-2"
        :class="data.item.status === '0'? 'text-success' : 'text-danger'"
      >
        {{ data.item.status === '0'? 'Açık Görüşme' : 'Kapalı Görüşme' }}
      </div>
    </template>
    <template #cell(username)="data">
      <div v-if="data.item.username">
        {{ data.item.username }}
      </div>
      <div v-else>
        {{ data.item.dealer_user }}
        <div class="font-small-2 text-info">
          Bayi Kullanıcısı
        </div>
      </div>
    </template>
  </b-table>
</template>
<script>
import { BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Interviews',
  components: {
    BTable,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'interview_subject',
          label: 'KONU',
        },
        {
          key: 'interview_status',
          label: 'DURUM',
        },
        {
          key: 'meet',
          label: 'TEMAS TİPİ',
        },
        {
          key: 'username',
          label: 'TEMSİLCİ',
        },
      ],
    }
  },
}
</script>
