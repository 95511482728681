<template>
  <div>
    <b-list-group flush>
      <b-list-group-item
        v-for="(line,key) in dataItem.offer_lines"
        :key="key"
        class="bg-transparent pl-0 pr-0"
      >
        <product
          :item-key="key"
          :id-product-groups="line.id_product_groups"
        />
        <b-row>
          <b-col cols="12">
            <product-title :item-key="key" />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <quantity
              :item-key="key"
              :calc-action="calcTotal"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <units :item-key="key" />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <listed-price
              :item-key="key"
              :calc-action="calcTotal"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <currency :item-key="key" />
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <product-content :item-key="key" />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <tax-rate
              :item-key="key"
              :calc-action="calcTotal"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center">
          <b-col cols="auto">
            <b-form-checkbox
              name="check-button"
              switch
              inline
              :checked="dataItem.offer_lines[key].discountStatus"
              @change="discountToggle(key)"
            >
              İskonto
            </b-form-checkbox>
          </b-col>
          <b-col v-if="dataItem.offer_lines[key].discountStatus">
            <discount-amount
              :item-key="key"
              :calc-action="calcTotal"
            />
          </b-col>
          <b-col v-if="dataItem.offer_lines[key].discountStatus">
            <discount-rate
              :item-key="key"
              :calc-action="calcTotal"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col v-if="dataItem.offer_lines[key].discountStatus">
            <discounted-price :item-key="key" />
          </b-col>
          <b-col>
            <total-price :item-key="key" />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-row>
      <b-col
        cols="12"
        class="mt-1"
      >
        <b-button
          variant="success"
          size="sm"
          @click="addItem"
        >
          <FeatherIcon icon="PlusIcon" />
          Ürün Ekle
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BListGroup, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import Product from '@/views/Admin/Offers/elements/Products/Product.vue'
import ProductTitle from '@/views/Admin/Offers/elements/Products/Title.vue'
import Quantity from '@/views/Admin/Offers/elements/Products/Quantity.vue'
import Units from '@/views/Admin/Offers/elements/Products/Units.vue'
import ListedPrice from '@/views/Admin/Offers/elements/Products/ListedPrice.vue'
import Currency from '@/views/Admin/Offers/elements/Products/Currency.vue'
import TaxRate from '@/views/Admin/Offers/elements/Products/Tax.vue'
import ProductContent from '@/views/Admin/Offers/elements/Products/Content.vue'
import DiscountAmount from '@/views/Admin/Offers/elements/Products/DiscountAmount.vue'
import DiscountRate from '@/views/Admin/Offers/elements/Products/DiscountRate.vue'
import DiscountedPrice from '@/views/Admin/Offers/elements/Products/DiscountedPrice.vue'
import TotalPrice from '@/views/Admin/Offers/elements/Products/TotalPrice.vue'

export default {
  name: 'Products',
  components: {
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    Product,
    ProductTitle,
    Quantity,
    Units,
    ListedPrice,
    Currency,
    TaxRate,
    ProductContent,
    DiscountAmount,
    DiscountRate,
    DiscountedPrice,
    TotalPrice,
  },
  data() {
    return {
      discount: false,
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
  methods: {
    calcTotal(index) {
      let totalPrice = this.dataItem.offer_lines[index].quantity * this.dataItem.offer_lines[index].listed_price
      /* indirim oranından indirim tutarı hesaplanıyor. */
      if (Number(this.dataItem.offer_lines[index].discount_rate) > 0) {
        const discountRate = Number(this.dataItem.offer_lines[index].discount_rate) / 100
        this.dataItem.offer_lines[index].discount_amount = totalPrice * discountRate
      }
      let discountedPrice = 0
      if (this.dataItem.offer_lines[index].discount_amount) {
        discountedPrice = totalPrice - this.dataItem.offer_lines[index].discount_amount
      }
      /* KDV Ekleniyor... */
      if (Number(this.dataItem.offer_lines[index].tax_rate) > 0) {
        const rate = Number(this.dataItem.offer_lines[index].tax_rate) / 100
        totalPrice *= (1 + rate)
        discountedPrice *= (1 + rate)
      }
      this.dataItem.offer_lines[index].total_price = totalPrice
      this.dataItem.offer_lines[index].discounted_price = discountedPrice
    },
    discountToggle(index) {
      this.dataItem.offer_lines[index].discountStatus = !this.dataItem.offer_lines[index].discountStatus
      if (this.dataItem.offer_lines[index].discountStatus === false) {
        this.dataItem.offer_lines[index].discount_amount = null
        this.dataItem.offer_lines[index].discount_rate = null
        this.dataItem.offer_lines[index].discounted_price = null
      }
    },
    addItem() {
      this.dataItem.offer_lines.push({
        id: null,
        title: null,
        content: null,
        quantity: null,
        listed_price: null,
        discounted_price: null,
        discount_amount: null,
        discount_rate: null,
        total_price: null,
        ordering: null,
        currency: null,
        tax_rate: null,
        discountStatus: false,
        id_products: null,
        id_product_groups: null,
        id_units: null,
        id_currencies: null,
        id_taxes: null,
      })
    },
    removeItem(index) {
      if (this.dataItem.offer_lines[index].id) {
        this.$store.dispatch('offers/removeLine', this.dataItem.offer_lines[index].id)
      }
      this.dataItem.offer_lines.splice(index, 1)
    },
  },
}
</script>
