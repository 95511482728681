<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    small
  >
    <template #cell(title)="data">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <b-button
            pill
            class="btn-icon"
            variant="flat-success"
            :to="'/app/offers/view/' + data.item.id"
            target="_blank"
          >
            <FeatherIcon icon="EyeIcon" />
          </b-button>
        </div>
        <div>
          <div>
            {{ data.item.title }}
          </div>
          <div class="text-primary font-small-2">
            {{ data.item.onumber }}
          </div>
        </div>
      </div>
    </template>
    <template #cell(odate)="data">
      <div>
        {{ moment(data.item.odate).format('LL') }}
      </div>
    </template>
    <template #cell(offer_status)="data">
      <div>
        {{ data.item.offer_status }}
      </div>
    </template>
    <template #cell(username)="data">
      <div v-if="data.item.username">
        {{ data.item.username }}
      </div>
      <div v-else>
        {{ data.item.dealer_user }}
        <div class="font-small-2 text-info">
          Bayi Kullanıcısı
        </div>
      </div>
    </template>
  </b-table>
</template>
<script>
import { BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Interviews',
  components: {
    BTable,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'TEKLİF',
        },
        {
          key: 'odate',
          label: 'TEKLİF TARİHİ',
        },
        {
          key: 'offer_status',
          label: 'DURUM',
        },
        {
          key: 'username',
          label: 'TEMSİLCİ',
        },
      ],
    }
  },
}
</script>
