<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    small
  >
    <template #cell(service_type)="data">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <b-button
            pill
            class="btn-icon"
            variant="flat-success"
            :to="'/app/services/view/' + data.item.id"
            target="_blank"
          >
            <FeatherIcon icon="EyeIcon" />
          </b-button>
        </div>
        <div>
          <div>
            {{ data.item.service_type }}
          </div>
          <div class="text-primary font-small-2">
            {{ moment(data.item.sdate).format('DD.MM.YYYY') }} -
            {{ moment(data.item.edate).format('DD.MM.YYYY') }}
          </div>
        </div>
      </div>
    </template>
    <template #cell(service_status)="data">
      <div>
        {{ data.item.service_status }}
      </div>
    </template>
  </b-table>
</template>
<script>
import { BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Interviews',
  components: {
    BTable,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'service_type',
          label: 'SERVİS İŞLEMİ',
        },
        {
          key: 'service_status',
          label: 'DURUM',
        },
        {
          key: 'username',
          label: 'TEMSİLCİ',
        },
      ],
    }
  },
}
</script>
